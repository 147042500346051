import "./ObtainFinancingForm.css";
import React, { useContext, useEffect, useState } from "react";
import MultiplesInputs from "../../molecules/formComponents/multiplesInputs/MultiplesInputs";
import Radio from "../../molecules/formComponents/radio/Radio";
import { useForm } from "react-hook-form";
import Input from "../../molecules/formComponents/input/Input";
import NumberInput from "../../molecules/formComponents/numberInput/NumberInput";
import { emailValidation } from "../../../utils/formValidation/FormValidation";
import Select from "../../molecules/formComponents/select/Select";
import { fetchUserDropdownApi } from "../../../api/AccountApi";
import AppContext from "../../../context/AppContext";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import { fetchAllActivitesByIdApi } from "../../../api/ParcoursApi";
import { normalizeString } from "../../../utils/Utils";

function ObtainFinancingForm() {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm();
  const Form = { register, setValue, watch, unregister };
  const { createNotification } = useContext(AppContext);

  const [usersTypes, setUsersTypes] = useState([]);
  const [isActivitiesDropdownVisible, setIsActivitiesDropdownVisible] = useState(false);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    fetchDropdownValues();
    fetchActivities();

    document.addEventListener("click", hideActivitiesDropdown);

    return () => document.removeEventListener("click", hideActivitiesDropdown);
  }, []);

  async function fetchDropdownValues() {
    try {
      const types = (await fetchUserDropdownApi("type")).data;
      setUsersTypes(types);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de données importantes.&nbsp;
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function fetchActivities() {
    try {
      setActivities((await fetchAllActivitesByIdApi()).data.sort((a, b) => a.description.localeCompare(b.description)));
    } catch (e) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de données importantes. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function formSubmit(values) {
    // TODO
    createNotification(<>Cette fonctionnalité n'est pas encore disponible.</>);
  }

  function setChosenActivity(activity) {
    setValue("activity", activity.description);
  }

  function hideActivitiesDropdown() {
    setIsActivitiesDropdownVisible(false);
  }

  function showActivitiesDropdown(e) {
    e.stopPropagation();

    setIsActivitiesDropdownVisible(true);
  }

  return (
    <>
      <fieldset className='borderless-fieldset full-page-form obtain-financing-form'>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Radio
            label='Type de bien&nbsp;*'
            name='property_type'
            error={errors?.property_type?.message}
            useForm={Form}
            content={[
              {
                value: 1,
                label: "Fonds de commerce",
              },
              {
                value: 2,
                label: "Titres de société",
              },
              {
                value: 3,
                label: "Murs commerciaux",
              },
              {
                value: 4,
                label: "Droit au bail",
              },
              {
                value: 5,
                label: "Autre",
              },
            ]}
          />
          <Input
            visible={watch("property_type") === "5"}
            name='other_property_type'
            label='Précisez le type de bien&nbsp;*'
            useForm={Form}
            error={errors?.other_property_type?.message}
          />
          <Input
            visible={watch("property_type") === "1" || watch("property_type") === "2"}
            useForm={Form}
            placeholder='Cliquez et déroulez, ou cherchez par mot-clé...'
            label='activité'
            name='activity'
            error={errors?.activity?.message}
            icon='search'
            onClick={showActivitiesDropdown}
            preventAutoComplete
            validation={{}}
          />
          <ul className={"activites-list" + (isActivitiesDropdownVisible ? "" : " h-none")}>
            {activities
              .filter(activity => normalizeString(activity.description).includes(normalizeString(watch("activity"))))
              .map((activities, key) => {
                return (
                  <li key={key} onClick={() => setChosenActivity(activities)}>
                    {activities.description}
                  </li>
                );
              })}
            {activities.length === 0 && <li className='activites-no-result'>Aucun résultat</li>}
          </ul>
          <NumberInput
            icon='euro'
            error={errors?.dernier_ca_ht?.message}
            name='dernier_ca_ht'
            className='mt-md'
            label='Dernier CA HT&nbsp;*'
            useForm={Form}
            visible={watch("property_type") === "1" || watch("property_type") === "2"}
          />
          <div className='row-1000'>
            <NumberInput
              icon='euro'
              error={errors?.ebe_retraite?.message}
              name='ebe_retraite'
              label='EBE retraité&nbsp;*'
              useForm={Form}
              visible={watch("property_type") === "1" || watch("property_type") === "2"}
            />
            <NumberInput
              icon='euro'
              error={errors?.prix_vente_net?.message}
              name='prix_vente_net'
              label='Prix de vente net vendeur&nbsp;*'
              useForm={Form}
            />
          </div>
          <div className='row-1000 mt-md'>
            <Input
              error={errors?.zip_code?.message}
              name='zip_code'
              label={`Code postal ${watch("property_type") === "1" || watch("property_type") === "2" ? "de l'affaire" : "du bien"} à financer *`}
              useForm={Form}
            />
            <Input
              error={errors?.city?.message}
              name='city'
              label={`Ville ${watch("property_type") === "1" || watch("property_type") === "2" ? "de l'affaire" : "du bien"} à financer * `}
              useForm={Form}
            />
          </div>
          <div className='row-1000 mt-md'>
            <Input error={errors?.first_name?.message} name='first_name' label='Prénom&nbsp;*' useForm={Form} />
            <Input error={errors?.last_name?.message} name='last_name' label='Nom&nbsp;*' useForm={Form} />
          </div>
          <div className='row-1000'>
            <NumberInput
              error={errors?.phone?.message}
              type='phone'
              name='phone'
              label='Téléphone&nbsp;*'
              useForm={Form}
            />
            <Input
              error={errors?.email?.message}
              name='email'
              label='Email&nbsp;*'
              validation={emailValidation}
              useForm={Form}
            />
          </div>
          <Select
            useForm={Form}
            error={errors?.user_type_id?.message}
            name='user_type_id'
            label='Je suis&nbsp;*'
            children={usersTypes.map(type => (
              <option key={type.id} id={type.id} value={type.id}>
                {type.description}
              </option>
            ))}
          />
          <div className='form-legend'>* Obligatoire</div>
          <div className='centered'>
            <FilledButton padding='10px 25px' type='submit'>
              Envoyer ma demande pour être contacté
            </FilledButton>
          </div>
        </form>
      </fieldset>
    </>
  );
}

export default ObtainFinancingForm;
