import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Radio from "../../../../molecules/formComponents/radio/Radio";

import { max2250Validation, numberValidation } from "../../../../../utils/formValidation/FormValidation";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { formatObjectForPosting, resetObjectValues } from "../../../../../utils/Utils";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto";
import AppContext from "../../../../../context/AppContext";

function CaracteristiquesLocalFdc({ id, index }) {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const {
    hasLocalDependance,
    handleTabs,
    moveToNextStep,
    step,
    setStep,
    submitFormsFdc,
    getStepToGo,
    setGoToStep,
    resetFormsRef,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const { showTooltip } = useContext(AppContext);

  const inputForm = { register, setValue, watch, unregister };
  const [localDependance, setLocalDependance] = useState(false);
  const description_locaux = watch("description_locaux_checkbox");

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();

    setLocalDependance(hasLocalDependance(estimationValues));
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["qualite_local"], setValue, estimationValues);
    setValue("notes.note_step_9", estimationValues?.notes?.note_step_9);

    if (estimationValues?.qualite_local?.description_locaux) {
      setValue("description_locaux_checkbox", true);
    }
  }, [estimationValues]);

  async function formSubmit(values) {
    if (!values.description_locaux_checkbox && values.qualite_local) values.qualite_local.description_locaux = null;

    delete values.description_locaux_checkbox;

    if (!hasLocalDependance(estimationValues)) values = resetObjectValues(values);

    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <fieldset className='borderless-fieldset'>
        <h2>caractéristiques du local d'activité</h2>
        <p className={!localDependance ? "mt-3" : "d-none"}>
          Pas besoin de remplir cette étape si vous ne disposez pas de local commercial !
        </p>
        <div className='row-1000'>
          <NumberInput
            useForm={inputForm}
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            visible={localDependance}
            label='largeur de façade (en mètres linéaires) si local avec pignon sur rue'
            name='qualite_local.largeur_facade'
            error={errors?.qualite_local?.largeur_facade?.message}
          />
          <div className='w-100' />
        </div>
        <div className='row-1000'>
          <Radio
            visible={localDependance}
            label='local d’angle'
            direction='column'
            useForm={inputForm}
            error={errors?.qualite_local?.local_angle?.id?.message}
            name='qualite_local.local_angle.id'
            content={[
              {
                label: "Sans objet",
                value: 1,
              },
              {
                label: "Non",
                value: 2,
              },
              {
                label: "Oui",
                value: 3,
              },
            ]}
          />
          <Radio
            visible={localDependance}
            label='local de plain-pied'
            direction='column'
            useForm={inputForm}
            error={errors?.qualite_local?.local_plain_pied?.id?.message}
            name='qualite_local.local_plain_pied.id'
            content={[
              {
                label: "Sans objet",
                value: 1,
              },
              {
                label: "Non",
                value: 2,
              },
              {
                label: "Oui",
                value: 3,
              },
            ]}
          />
        </div>
        <div className='row-1000'>
          <Radio
            visible={localDependance}
            label='terrasse / jardin / terrain / piscine'
            direction='column'
            useForm={inputForm}
            error={errors?.qualite_local?.equipement_exterieur?.id?.message}
            name='qualite_local.equipement_exterieur.id'
            content={[
              {
                label: "Sans objet",
                value: 1,
              },
              {
                label: "Non",
                value: 2,
              },
              {
                label: "Oui",
                value: 3,
              },
            ]}
          />
          <Radio
            visible={localDependance}
            label='réserve / espace de stockage'
            direction='column'
            useForm={inputForm}
            error={errors?.qualite_local?.reserve?.id?.message}
            name='qualite_local.reserve.id'
            content={[
              {
                label: "Sans objet",
                value: 1,
              },
              {
                label: "Non",
                value: 2,
              },
              {
                label: "Oui",
                value: 3,
              },
            ]}
          />
        </div>
        <div className='row-1000'>
          <Radio
            visible={localDependance}
            label='stationnement facile'
            direction='column'
            useForm={inputForm}
            error={errors?.qualite_local?.stationnement_facile?.id?.message}
            name='qualite_local.stationnement_facile.id'
            content={[
              {
                label: "Sans objet",
                value: 1,
              },
              {
                label: "Non",
                value: 2,
              },
              {
                label: "Oui",
                value: 3,
              },
            ]}
          />
          <div className='w-100' />
        </div>
        <Checkbox
          visible={localDependance}
          name='description_locaux_checkbox'
          label={
            <>
              Je souhaite ajouter une description des locaux
              <Tuto onClick={() => showTooltip("step_9_description_locaux_checkbox.webp")} />
            </>
          }
          useForm={inputForm}
        />
        <Textarea
          error={errors?.qualite_local?.description_locaux?.message}
          validation={max2250Validation}
          visible={localDependance && description_locaux}
          useForm={inputForm}
          name='qualite_local.description_locaux'
          label='description des locaux'
        />
        <Textarea
          validation={max2250Validation}
          visible={localDependance}
          useForm={inputForm}
          name='notes.note_step_9'
          error={errors?.notes?.note_step_9?.message}
          label={
            <>
              notes et remarques
              <Tuto onClick={() => showTooltip("step_9_note_step_9.webp")} />
            </>
          }
          className='mt-md'
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default CaracteristiquesLocalFdc;
