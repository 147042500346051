import "./FinancingPlan.css";
import React from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import FinancingPlanForm from "../../../components/forms/financingPlanForm/FinancingPlanForm";
import { Link } from "react-router-dom";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";
import { useAuth } from "../../../context/AuthContext";

function FinancingPlan() {
  const { getUuid, canAccess, accesses } = useAuth();

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              to: "/",
              name: "Accueil",
            },
            {
              to: "/plan-financement",
              name: "Plan de financement",
            },
          ]}
        />
        <Banner
          title='Plan de financement'
          subtitle={
            getUuid() ? (
              <Link className='link-with-arrow' to='/mon-compte/mes-outils/mes-plans-de-financement'>
                <ArrowSm /> Accéder à mes outils (plans de financement)
              </Link>
            ) : (
              "Réalisez et sauvegardez vos plans de financement en quelques clics"
            )
          }
        />
        <FinancingPlanForm />
        {canAccess("credipro_form_access", accesses) && (
          <>
            <h2 className='mt-xl'>Obtenir un financement professionnel</h2>
            <p>
              Vous recherchez un financement professionnel ? Notre partenaire vous permet de connaître la finançabilité
              du projet et de vous accompagner intégralement sur l'obtention du prêt professionnel.{" "}
              <Link to='/obtenir-financement' className='link'>
                Cliquez ici pour formuler votre demande
              </Link>
              .
            </p>
          </>
        )}
      </section>
    </>
  );
}

export default FinancingPlan;
