import React from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import ObtainFinancingForm from "../../../components/forms/obtainFinancingForm/ObtainFinancingForm";

function ObtainFinancing() {
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              to: "/",
              name: "Accueil",
            },
            {
              to: "/obtenir-financement",
              name: "Obtenir un financement",
            },
          ]}
        />
        <Banner
          title='Obtenir un financement'
          subtitle={
            <>
              Vous recherchez un financement professionnel ?<br /> Complétez le formulaire ci-dessous pour être contacté
              par notre partenaire, qui vous indiquera la finançabilité du projet.
            </>
          }
        />
        <ObtainFinancingForm />
      </section>
    </>
  );
}

export default ObtainFinancing;
